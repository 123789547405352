import type { ButtonProps } from "@chakra-ui/react"
import { Button, Icon, Link, Text } from "@chakra-ui/react"
import { useState, type FC, useEffect } from "react"

import { useLocation } from "common/hooks/useLocation"
import FarcasterBlack from "ui/components/icons/FarcasterBlack"

type Props = {
  title: string
  url: string
}
const ShareToFarcasterButton: FC<Props & ButtonProps> = ({
  title,
  url,
  ...buttonProps
}) => {
  const { origin } = useLocation()
  const [shareToFarcasterUrl, setShareToFarcasterUrl] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    if (origin) {
      const shareUrl = `https://warpcast.com/~/compose?text=${encodeURIComponent(
        title,
      )}&embeds[]=${origin}${url}`
      setShareToFarcasterUrl(shareUrl)
    }
  }, [origin, title, url])

  if (!shareToFarcasterUrl) {
    return null
  }

  return (
    <Button
      isExternal
      as={Link}
      className="no-underline"
      href={shareToFarcasterUrl}
      leftIcon={<Icon as={FarcasterBlack} />}
      variant={{ base: "secondary", md: "tertiary" }}
      {...buttonProps}
    >
      <Text>Share to Farcaster</Text>
    </Button>
  )
}

export default ShareToFarcasterButton
