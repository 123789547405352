import type { FC } from "react"
import { Flex, Stack, Text } from "@chakra-ui/react"
import { isAddress } from "@ethersproject/address"

import { useDevice } from "common/hooks/useDevice"
import type { Account } from "query/graphql"
import UserAvatar from "common/components/UserAvatar"
import Link from "common/components/Link"
import { ROUTES } from "common/constants/routes"
import { shortString, subString } from "common/helpers/string"

type AddressInfoProps = {
  label?: string
  account: Pick<Account, "address" | "name" | "ens" | "picture">
  usePicture?: boolean
  slug: string
  displayShortAddress?: boolean
}

const AddressInfo: FC<AddressInfoProps> = ({
  label,
  account,
  slug,
  usePicture = true,
  displayShortAddress = false,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()
  const { address, name, ens, picture } = account

  const endpoint = ROUTES.governance.delegate.profile(slug, address)

  const getNameLabel = () => {
    if (ens) return ens
    if (name) return name

    return address
  }

  const nameLabel = getNameLabel()

  return (
    <Flex align="center" direction="row">
      <Stack
        isInline
        align="center"
        maxW={{ base: "100%", lg: "full" }}
        pr={{ base: 2, sm: 0 }}
        py={{ base: 1, sm: 2 }}
        spacing={{ base: 1, sm: 1 }}
      >
        {usePicture ? (
          <UserAvatar address={address} mr={1} size="sm" src={picture} />
        ) : null}
        {label ? (
          <>
            <Text
              display={onLargeDevice}
              textStyle="body.regular.md"
              w="max-content"
            >
              {label}
            </Text>
            <Text
              display={onLittleDevice}
              textStyle="body.regular.sm"
              w="max-content"
            >
              {label}
            </Text>
          </>
        ) : null}
        <Link href={endpoint}>
          <Text
            display={onLargeDevice}
            overflowWrap="break-word"
            textStyle="body.bold.md"
          >
            {isAddress(nameLabel) && displayShortAddress
              ? shortString(nameLabel)
              : subString(nameLabel, 35)}
          </Text>
          <Text display={onLittleDevice} textStyle="body.regular.md">
            {isAddress(nameLabel)
              ? shortString(nameLabel)
              : subString(nameLabel, 30)}
          </Text>
        </Link>
      </Stack>
    </Flex>
  )
}

export default AddressInfo
