import type { ButtonProps } from "@chakra-ui/react"
import { Button, Icon, Link, Text } from "@chakra-ui/react"
import { useState, type FC, useEffect } from "react"

import { useLocation } from "common/hooks/useLocation"
import LensProtocol from "ui/components/icons/LensProtocol"

type Props = {
  title: string
  url: string
}
const ShareToLensButton: FC<Props & ButtonProps> = ({
  title,
  url,
  ...buttonProps
}) => {
  const { origin } = useLocation()
  const [shareToLensUrl, setShareToLensUrl] = useState<string | undefined>(
    undefined,
  )

  useEffect(() => {
    if (origin) {
      const shareUrl = `https://hey.xyz/?text=${encodeURIComponent(
        title,
      )}&url=${origin}${url}`
      setShareToLensUrl(shareUrl)
    }
  }, [origin, title, url])

  if (!shareToLensUrl) {
    return null
  }

  return (
    <Button
      isExternal
      as={Link}
      className="no-underline"
      href={shareToLensUrl}
      leftIcon={<Icon as={LensProtocol} h={5} ml="-4px" mr="-2px" w={5} />}
      variant={{ base: "secondary", md: "tertiary" }}
      {...buttonProps}
    >
      <Text>Share to Lens</Text>
    </Button>
  )
}

export default ShareToLensButton
